// contentHeader.scss

@import '../../../../assets/css/foundation.scss';

.content_header {
  width: 100%;
  background: #667085;
  opacity: 0.9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11), inset 0px 4px 4px rgba(0, 0, 0, 0.09);
  @include flex(flex, $display-flex-row-no, center, space-between);
  padding: 12px 24px;
  > .title {
    @include NanumSquare-label-18UB($font-color: $white);
    cursor: pointer;
  }
  > .right_box {
    @include flex(flex, $display-flex-row-no, center, space-between);
    > li {
      @include NanumSquare-label-14B($font-color: $neutral-300);
      margin-right: 30px;
      cursor: pointer;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover,
      &.on {
        color: $white;
      }
    }
}
}

@media (max-width: 1402px) {
  .content_header {
    display: none;
  }
}
