// updateList.scss

@import '../../../../assets/css/foundation.scss';

.update {
  &__inner {
    > .board {
      >.board__list {
        position: relative;
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        margin-bottom: 48px;
        cursor: pointer;
        > .date {
          flex: 1;
          @include NanumSquare-headline-28UB($font-color: $neutral-600);
        }
        > img {
          flex: 5;
          width: 500px;
          height: 250px;
          object-fit: cover;
          margin: 0 24px 0 32px;
        }
        > p:last-of-type {
          flex: 5;
          word-break: break-all;
          @include NanumSquare-body-16UB($font-color: $neutral-400);
          > .title {
            @include NanumSquare-title-22UB($font-color: $neutral-700);
            display: block;
            margin-bottom: 8px;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .update {
    &__inner {
      > .board {
        @include flex(flex, $display-flex-row-wrap, flex-start, space-between);
        >.board__list {
          width: calc(50% - 12px);
          @include flex(flex, $display-flex-column-no, flex-start, space-between);
          margin-bottom: 24px;
          cursor: pointer;
          > .date {
            @include NanumSquare-title-22UB($font-color: $neutral-600);
          }
          > img {
            margin: 0;
            width: 100%;
            height: 172px;
            min-height: 172px;
            object-fit: cover;
            overflow: hidden;
            flex: inherit;
          }
          > p:last-of-type {
            @include NanumSquare-body-14B($font-color: $neutral-400);
            > .title {
              @include NanumSquare-title-18UB($font-color: $neutral-700);
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .update {
    &__inner {
      > .board {
        @include flex(flex, $display-flex-row-wrap, flex-start, space-between);
        >.board__list {
          width: 100%;
          @include flex(flex, $display-flex-column-no, flex-start, space-between);
          margin-bottom: 24px;
          cursor: pointer;
          > .date {
            @include NanumSquare-title-18UB($font-color: $neutral-600);
          }
          > img {
            margin: 0;
            width: 100%;
            height: 164px;
            object-fit: cover;
          }
          > p:last-of-type {
            @include NanumSquare-body-14($font-color: $neutral-400);
            > .title {
              @include NanumSquare-title-16UB($font-color: $neutral-700);
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}