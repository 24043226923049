@import '../../../assets/css/foundation.scss';


.content {
  &.not_found {
    @include flex(flex, $display-flex-column-no, center, center);
    gap: 32px;
    width: 100%;
    margin: 0;
    height: calc(100vh - 64px);
  }
}

.not_found {
  &--image {
    @include image-style(305px, 254px, url(../../../assets/images/not_found_page_images.png) no-repeat 50% 50%, contain);
  }
  &__content {
    @include flex(flex, $display-flex-column-no, center, center);
    gap: 20px;
  }
  &__title {
    @include NanumSquare-headline-28UB($font-color: $neutral-700, $align: center);
  }
  &__description {
    @include NanumSquare-body-16UB($font-color:$neutral-500, $align: center);
  }
  &__footer {
    @include flex(flex, $display-flex-row-no, center, center);
    gap: 10px;
  }
}