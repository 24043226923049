// normalButton.scss

@import '../../../assets/css/foundation.scss';

.normal_button {
  // background: $white;
  // border: 1px solid $neutral-300;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  @include flex(flex, $display-flex-row-no, center, center);

  >p {
    @include text-style($neutral-700, 14px, 700, 20px, center);
    letter-spacing: 0.1px;
  }

  > .icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 4px;

    &::after,
    &::before {
      position: absolute;
      content: "";
      border-radius: 1px;
    }
  }
  
  > .icon.leading {
    &::after {
      top: calc(50% - 6px);
      left: calc(50% - 1px);
      width: 2px;
      height: 12px;
    }

    &::before {
      top: calc(50% - 1px);
      left: calc(50% - 6px);
      width: 12px;
      height: 2px;
    }
  }

  > .icon.trailing {
    &::after {
      top: calc(50% - 6.2px);
      left: calc(50% + 2px);
      width: 2px;
      height: 8px;
      transform: rotate(-45deg);
    }

    &::before {
      top: calc(50% + 1.2px);
      left: calc(50% - 1px);
      width: 8px;
      height: 2px;
      transform: rotate(-45deg);
    }
  }
  
  &.xs {
    padding: 4px 12px;
    p {
      @include NanumSquare-label-12B;
    }

    &.leading {
      padding: 4px 12px 4px 8px;
    }

    &.trailing {
      padding: 4px 8px 4px 12px;
    }
  }

  &.sm {
    padding: 6px 16px;
    p {
      @include NanumSquare-label-14($font-weight: 700);
      line-height: 20px;
    }

    &.leading {
      padding: 6px 16px 6px 10px;
    }

    &.trailing {
      padding: 6px 10px 6px 16px;
    }
  }

  &.md {
    padding: 10px 18px;
    p {
      @include NanumSquare-label-14;
    }

    &.leading {
      padding: 6px 18px 6px 12px;
    }

    &.trailing {
      padding: 6px 12px 6px 18px;
    }
  }

  &.lg {
    padding: 10px 20px;
    p {
      @include NanumSquare-label-16B;
    }

    &.leading {
      padding: 6px 20px 6px 14px;
    }

    &.trailing {
      padding: 6px 14px 6px 20px;
    }
  }

  &.xl {
    padding: 12px 20px;
    p {
      @include NanumSquare-label-16B;
    }

    &.leading {
      padding: 6px 20px 6px 14px;
    }

    &.trailing {
      padding: 6px 14px 6px 20px;
    }
  }

  &.txl {
    padding: 16px 32px;
    p {
      @include NanumSquare-label-18UB;
    }

    &.leading {
      padding: 6px 32px 6px 26px;
    }

    &.trailing {
      padding: 6px 26px 6px 32px;
    }
  }

  &.round {

    &.xs {
      border-radius: 20px;
    }

    &.sm {
      border-radius: 20px;
    }

    &.md {
      border-radius: 30px;
    }

    &.lg {
      border-radius: 30px;
    }

    &.xl {
      border-radius: 30px;
    }

    &.txl {
      border-radius: 30px;
    }
  }

  &.box {

    &.xs {
      border-radius: 6px;
    }

    &.sm {
      border-radius: 8px;
    }

    &.md {
      border-radius: 10px;
    }

    &.lg {
      border-radius: 10px;
    }

    &.xl {
      border-radius: 10px;
    }

    &.txl {
      border-radius: 12px;
    }
  }

  // primary
  &.primary {
    background: $primary-600;
    border: 1px solid $primary-600;

    >p {
      color: $white;
    }

    &:hover,
    &:focus {
      background: $primary-700;
      border: 1px solid $primary-700;
    }

    &.disable {
      background: $primary-200;
      border: 1px solid $primary-200;
      cursor: default;
    }

    > .icon {
      &::after,
      &::before {
        background: $white;
      }
    }
  }

  // primary line
  &.primary_line {
    background: $white;
    border: 1px solid $primary-600;

    >p {
      color: $primary-600;
    }

    > .icon {
      &::after,
      &::before {
        background: $primary-600;
      }
    }

    &:hover {
      background: $primary-50;
      border: 1px solid $primary-300;

      >p {
        color: $primary-700;
      }

      > .icon {
        &::after,
        &::before {
          background: $primary-700;
        }
      }
    }

    &:focus {
      background: $white;
      border: 1px solid $primary-300;

      >p {
        color: $primary-700;
      }
    }

    &.disable {
      background: $white;
      border: 1px solid $primary-200;
      cursor: default;

      >p {
        color: $primary-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $primary-300;
        }
      }
    }
  }

  // secondary color
  &.secondary_color {
    background: $primary-50;
    border: 1px solid $primary-50;

    >p {
      color: $primary-700;
    }
    > .icon {
      &::after,
      &::before {
        background: $primary-700;
      }
    }

    &:hover {
      background: $primary-100;
      border: 1px solid $primary-100;
    }

    &:focus {
      background: $primary-50;
      border: 1px solid $primary-50;
    }

    &.disable {
      background: $primary-25;
      border: 1px solid $primary-25;
      cursor: default;

      >p {
        color: $primary-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $primary-300;
        }
      }
    }
  }

  // third color
  &.third_color {
    background: $info-50;
    border: 1px solid $info-50;

    >p {
      color: $info-500;
    }
    > .icon {
      &::after,
      &::before {
        background: $info-700;
      }
    }

    &:hover {
      background: $info-500;
      border: 1px solid $info-500;
      >p {
        color: $white;
      }
    }

    &:focus {
      background: $info-500;
      border: 1px solid $info-500;
      >p {
        color: $white;
      }
    }

    &.disable {
      background: $info-25;
      border: 1px solid $info-25;
      cursor: default;

      >p {
        color: $info-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $info-300;
        }
      }
    }
  }

  // quaternary
  &.quaternary {
    background: $neutral-600;
    border: 1px solid $neutral-600;

    >p {
      color: $white;
    }

    > .icon {
      &::after,
      &::before {
        background: $white;
      }
    }

    &:hover,
    &:focus {
      background: $neutral-700;
      border: 1px solid $neutral-700;
    }

    &.disable {
      background: $neutral-300;
      border: 1px solid $neutral-300;
      cursor: default;
    }
  }

  // secondary gray
  &.secondary_gray {
    background: $white;
    border: 1px solid $neutral-300;

    >p {
      color: $neutral-700;
    }

    > .icon {
      &::after,
      &::before {
        background: $neutral-700;
      }
    }

    &:hover {
      background: $neutral-50;
      border: 1px solid $neutral-400;

      >p {
        color: $neutral-800;
      }

      > .icon {
        &::after,
        &::before {
          background: $neutral-800;
        }
      }
    }

    &.disable {
      background: $white;
      border: 1px solid $neutral-200;
      cursor: default;

      >p {
        color: $neutral-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $neutral-300;
        }
      }
    }
  }

  // tertiary color
  &.tertiary_color {
    background: transparent;
    border: 1px solid transparent;

    >p {
      color: $primary-700;
    }

    > .icon {
      &::after,
      &::before {
        background: $primary-700;
      }
    }

    &:hover {
      background: $primary-50;
    }

    &.disable {
      cursor: default;
      >p {
        color: $neutral-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $neutral-300;
        }
      }
    }
  }

  // tertiary gray
  &.tertiary_gray {
    background: transparent;
    border: 1px solid transparent;

    >p {
      color: $neutral-500;
    }

    > .icon {
      &::after,
      &::before {
        background: $neutral-500;
      }
    }

    &:hover {
      background: $neutral-100;

      >p {
        color: $neutral-600;
      }

      > .icon {
        &::after,
        &::before {
          background: $neutral-500;
        }
      }
    }

    &.disable {
      cursor: default;
      >p {
        color: $neutral-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $neutral-300;
        }
      }
    }
  }

  // link color
  &.link_color {
    background: transparent;
    border: 1px solid transparent;

    >p {
      color: $primary-700;
    }

    > .icon {
      &::after,
      &::before {
        background: $primary-700;
      }
    }

    &:hover {
      >p {
        color: $primary-900;
      }

      > .icon {
        &::after,
        &::before {
          background: $primary-900;
        }
      }
    }

    &.disable {
      cursor: default;
      >p {
        color: $primary-300;
      }

      > .icon {
        &::after,
        &::before {
          background: $primary-300;
        }
      }
    }
  }

  // link gray
  &.link_gray {
    background: transparent;
    border: 1px solid transparent;

    >p {
      color: $neutral-500;
    }

    > .icon {
      &::after,
      &::before {
        background: $neutral-500;
      }
    }

    &:hover {
      >p {
        color: $neutral-600;
      }
      > .icon {
        &::after,
        &::before {
          background: $neutral-700;
        }
      }
    }

    &.disable {
      cursor: default;
      >p {
        color: $neutral-300;
      }
      > .icon {
        &::after,
        &::before {
          background: $neutral-300;
        }
      }
    }
  }

  &.primary_line_white {
    border: 1px solid $white;

    >p {
      color: $white;
    }

    &:hover,
    &:focus {
      background: $primary-700;
      border: 1px solid $primary-700;
    }

    &.disable {
      background: $primary-200;
      border: 1px solid $primary-200;
      cursor: default;
    }

    > .icon {
      &::after,
      &::before {
        background: $white;
      }
    }
  }

  // red
  &.red {
    background: $error-600;
    border: 1px solid $error-600;

    >p {
      color: $white;
    }

    &:hover,
    &:focus {
      background: $error-700;
      border: 1px solid $error-700;
    }

    &.disable {
      background: $error-200;
      border: 1px solid $error-200;
      cursor: default;
    }

    > .icon {
      &::after,
      &::before {
        background: $white;
      }
    }
  }

  // customer
  &.to_main {
    > p {
      @include NanumSquare-label-16UB($font-color: $white);
    }
  } 
  &.my_page {
    > p {
      @include NanumSquare-label-16UB($font-color: $neutral-700);
    }
  }
  &.to_prev {
    > p {
      @include NanumSquare-label-16UB($font-color: $primary-600);
    }
  }
}

@media (max-width: 1402px) {
  .normal_button {
  
    &.md {
      padding: 12px 20px;
  
      &.leading {
        padding: 6px 20px 6px 14px;
      }
  
      &.trailing {
        padding: 6px 14px 6px 20px;
      }
    }
  }
}