@import '../../assets/css/foundation.scss';
.content.register-done {
  width: 100%;
  margin: 0;
  min-height: calc(100vh - 64px);
  // padding: 48px 375px;
  &.register-done {
    @include flex(flex, $display-flex-column-no, center, center);
  }
}

.done {
  width: 100%;
  @include flex(flex, $display-flex-column-no, center, center);
  &__base {
    padding: 72px 32px;
    width: 550px;
    background: $white;
    border: 1px solid $neutral-200;
    border-radius: 25px;
  }
  &__wrapper {
    width: 486px;
    @include flex(flex, $display-flex-column-no, center, center);
    gap: 32px;
  }
  &__header {
    @include flex(flex, $display-flex-column-no, center, center);
    gap: 10px;
  }
  &__title {
    @include NanumSquare-headline-32UB($font-color: $primary-600);
  }
  &__description {
    @include NanumSquare-body-22B($font-color: $neutral-900, $align: center);
  }
}



.log-wrapper {
  @include flex(flex, $display-flex-row-no, center, center);
  gap: 4px;
  .log-icon {
    width: 24px;
    height: 24px;

    &.check {
      @include background-image(url(../../assets/images/check-log-icon.png) no-repeat 50% 50%, contain);
    }
  }
  > p {
    @include NanumSquare-label-14B($font-color: $success-500, $align: center);
  }
}

.done-button {
  &__wrapper {
    @include flex(flex, $display-flex-column-no, center, center);
    gap: 12px;
    width:100%;
  }
}


@media screen and (max-width: 1402px) {
  
}

@media screen and (max-width: 760px) {
  .done {
    &__base {
      width: 360px;
      padding: 72px 15px;
      border:none;
    }
    &__wrapper {
      width: 330px;
      padding: 0;
    }

    &__title {
      @include NanumSquare-headline-28UB($font-color:$primary-600, $align: center);
    }
    &__description {
      @include NanumSquare-title-16UB($font-color: $neutral-900, $align: center);
    }
  }
}