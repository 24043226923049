// terms.scss

@import '../../../assets/css/foundation.scss';

.terms,
.policy {
  width: 100%;
  min-height: calc(100vh - 64px);
  @include flex(flex, $display-flex-column-no, center, flex-start);
  padding-top: 112px;
  &__inner {
    position: relative;
    width: $web-width;
    @include flex(flex, $display-flex-column-no, center, flex-start);
    padding-bottom: 60px;
    >.page_top {
      width: 100%;
      @include flex(flex, $display-flex-column-no, flex-start, flex-start);
      border-bottom: 1px solid $neutral-200;

      >.title {
        @include NanumSquare-title-22UB;
        margin-bottom: 8px;
      }
      > .description {
        @include NanumSquare-body-12($font-color: $neutral-700);
        margin-bottom: 8px;
      }
    }
    > p {
      word-break: break-all;
      @include NanumSquare-body-14($font-color: $neutral-900);
      > .title {
        @include NanumSquare-body-16UB($font-color: $neutral-900);
      }
      > .dot {
        display: inline-block;
        transform: translateY(-2px);
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $neutral-900;
        margin: 0 5px;
      }
      > .depth1 {
        display: inline-block;
        content: "";
        width: 20px;
        height: 10px;
      }
      > .depth2 {
        display: inline-block;
        content: "";
        width: 40px;
        height: 10px;
      }
      > table {
        width: 100%;
        border-top: 1px solid $neutral-200;
        > thead,
        > tbody {
          width: 100%;
          > tr {
            border-bottom: 1px solid $neutral-200;
            th {
              background: $neutral-50;
            }
            th, td {
              border-right: 1px solid $neutral-200;
              @include NanumSquare-body-14($font-color: $neutral-700);
              text-align: center;
              padding: 15px 0;
              &:last-of-type {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1402px) {
  .terms,
  .policy {
    &__inner {
      padding: 0 24px;
      width: 100%;
    }
  }
}

@media (max-width: 760px) {
  .terms,
  .policy {
    &__inner {
      padding: 0 15px;
      width: 100%;
      >.page_top {
        >.title {
          @include NanumSquare-title-18UB;
        }
      }
    }
  }
}