// categoryBox.scss

@import '../../../assets/css/foundation.scss';

.category_box {
  @include flex(flex, $display-flex-row-no, center, center);
  border: 1px solid $neutral-300;
  border-radius: 8px;
  background: $white;
  overflow: hidden;
  > li {
    padding: 10px 16px;
    border-right: 1px solid $neutral-300;
    cursor: pointer;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    &:last-of-type {
      border-right: none;
    }
    &.on,
    &:hover {
      position: relative;
      background: $neutral-50;
      padding: 10px 16px;
      &.on {
        padding: 10px 16px 10px 34px;
        &::after {
          position: absolute;
          top: calc(50% - 4px);
          left: 17px;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $success-500;
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .event {
    .category_box {
      max-width: 223px;
      
      > li {
        position: relative;
        padding: 10px 17px;
        box-sizing: border-box;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 16px;
          height: 100%;
          background: $white;
          border-radius: 0 8px 8px 0;
        }
        &.on,
        &:hover {
          padding: 10px 17px;
          &::before {
            background: $neutral-50;
          }
          &.on {
            padding: 10px 16px 10px 34px;
            width: 91px;
          }
        }
        &:nth-of-type(1) {
          width: 73px;
        }
        &:nth-of-type(2) {
          width: 60px;
          &.on {
            width: 77px;
          }
        }
        &:nth-of-type(3) {
          width: 73px;
        }
      }
    }
  }
  
}