@import '../../../assets/css/foundation.scss';

// language box
.language_box {
  @include flex(flex, $display-flex-row-no, center, center);

  >.lang {
    @include text-style($neutral-500, 14px, 700, 20px, center);
    cursor: pointer;

    &.on {
      color: $neutral-700;
    }

    &:nth-of-type(1) {
      position: relative;
      padding-right: 12px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 25%;
        width: 2px;
        height: 50%;
        background: $neutral-300;
      }
    }

    &:nth-of-type(2) {
      margin-left: 12px;
    }
  }
}