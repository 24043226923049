// boardList.scss

@import '../../../../assets/css/foundation.scss';

.board_list {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $neutral-200;
  @include flex(flex, $display-flex-row-no, center, space-between);
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;

  // 카테고리
  >.category {
    padding: 4px 12px;
    border-radius: 16px;
    @include NanumSquare-label-12B($font-color : $neutral-700);
    text-align: center;
    margin-right: 32px;

    &.공지 {
      border: 1px solid $neutral-100;
      color: $neutral-700;
    }

    &.점검 {
      border: 1px solid $info-100;
      color: $info-700;
    }

    &.상점 {
      border: 1px solid $success-100;
      color: $success-700;
    }

    &.예정 {
      border: 1px solid $info-100;
      color: $info-700;
    }

    &.종료 {
      border: 1px solid $neutral-100;
      color: $neutral-700;
    }

    &.진행 {
      border: 1px solid $primary-100;
      color: $primary-700;
    }

    &.답변대기 {
      color: $info-700;
      background: $info-50;
    }

    &.답변완료 {
      color: $success-700;
      background: $success-50;
    }
  }

  // 제목
  >.title_box {
    flex: 30;
    @include flex(flex, $display-flex-row-no, center, flex-start);

    >.title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      word-break: break-all;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      @include NanumSquare-body-14($font-color : $neutral-700);

      >.comment {
        @include NanumSquare-body-14($font-color : $info-600);
      }
    }

    >.new {
      min-width: 16px;
      min-height: 16px;
      border-radius: 50%;
      background: url(../../../../assets/images/icon_new.png) no-repeat 50% 50%;
      background-size: contain;
      @include flex(flex, $display-flex-row-no, center, center);
      margin-left: 8px;
    }
  }

  // 유저 ID, Like, View box
  >.data_box {
    flex: 10;
    @include flex(flex, $display-flex-row-no, center, space-between);

    >.data_user {
      flex: 3;
      @include flex(flex, $display-flex-row-no, center, flex-start);

      >img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-size: contain;
        margin-right: 6px;
      }

      >p {
        @include NanumSquare-label-14($font-color : $neutral-700);
      }
    }

    >.like,
    >.view {
      flex: 2;
      @include flex(flex, $display-flex-row-no, center, center);

      >svg {
        width: 24px;
        height: 24px;

        >g {
          >path {
            fill: $neutral-400;
          }
        }
      }

      >p {
        @include NanumSquare-label-12UB($font-color : $neutral-700);
      }
    }
  }

  // 날짜 및 시간
  >.date {
    flex: 3;
    text-align: right;
    @include NanumSquare-body-12($font-color : $neutral-500, $align: right);
  }

  // 고정된f 게시물일 경우
  &.fixed {
    background: $neutral-50;

    >.title_box {
      position: relative;
      text-indent: 24px;

      // .new {
      //   display: none;
      // }

      &::after {
        position: absolute;
        top: calc(50% - 10px);
        content: "";
        width: 20px;
        height: 20px;
        background: url(../../../../assets/images/push_pin.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    >.data_box {
      display: none;
    }

  }

  &.notice,
  &.event,
  &.free-board,
  &.tip-board,
  &.screenshot-board {
    padding: 16px 28px;

    &.event,
    &.screenshot-board {
      position: relative;
      width: 570px;
      height: 375px;
      min-height: inherit;
      margin-bottom: 24px;
      padding: 0;
      background: none;
      border-bottom: none;
      box-sizing: border-box;
      border: 1px solid transparent;
      @include flex(flex, $display-flex-row-wrap, flex-start, flex-start);

      >img {
        position: relative;
        width: 100%;
        height: 285px;
        object-fit: cover;
      }

      >.title_box {
        text-indent: 0;

        .title {
          line-height: 26px;
        }
      }

      >p {
        flex: inherit;
        margin-right: 8px;

        &:last-of-type {
          width: 100%;
          text-align: left;

          >span {
            @include NanumSquare-label-12B($font-color: $neutral-500);
          }
        }

        &:last-of-type,
        &:first-of-type {
          margin-left: 16px;
        }
      }

      &.fixed {
        >.title_box {
          &::after {
            display: none;
          }
        }
      }

      &:hover {
        border: 1px solid $neutral-300;

        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 286px;
          background: $neutral-600;
          opacity: .5;
        }
      }

      &.screenshot-board {
        width: 374px;
        height: 281px;
        @include flex(flex, $display-flex-column-no, flex-start, flex-start);
        padding-bottom: 18px;

        >.category {
          display: none;
        }

        >img {
          width: 100%;
          height: 187px;
        }

        >.title_box {
          width: 100%;
          margin-right: 0;

          .title {
            padding-left: 16px;
            @include NanumSquare-title-18UB($font-color : $neutral-700, $align: left);
          }

          >.new {
            display: block;
          }
        }

        .data_box {
          flex: inherit;
          width: 100%;
          padding: 0 16px;
          @include flex(flex, $display-flex-row-no, center, space-between);

          >div {
            flex: inherit;
          }

          >.like {
            position: absolute;
            right: 80px;
          }
        }

        .date {
          display: none;
        }

        &:hover {
          &::after {
            height: 187px;
          }
        }
      }
    }
  }

  &.notice,
  &.inquiry,
  &.update {
    width: inherit;
    min-height: inherit;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    >.data_box {
      display: none;
    }

    &.inquiry {
      padding-top: 16px;
    }

    &.fixed {
      .new {
        display: none;
      }
    }
  }

  &.detail {
    width: inherit;
    min-height: inherit;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    cursor: default;

    >.category {
      margin-right: 4px;
    }

    &.update {
      >.category {
        display: none;
      }

      >.title_box {
        >.title {
          text-indent: 0;
        }

        &::after {
          display: none;
        }
      }
    }

    &.event {
      width: 100%;
      height: inherit;
      margin-bottom: 0;
      padding: 16px 0;
      border-bottom: 1px solid $neutral-200 !important;
      @include flex(flex, $display-flex-row-no, center, space-between);

      >.category {
        margin-left: 0 !important;
        margin-right: 4px;
      }

      >img {
        display: none;
      }

      >.title_box {
        // flex: inherit;
      }

      >.date {
        margin-right: 0;
        width: auto !important;

        >span {
          display: none;
        }
      }

      &:hover {
        border: 1px solid transparent !important;
        border-bottom: 1px solid $neutral-200 !important;

        &::after {
          display: none;
        }
      }
    }

    &.free-board,
    &.tip-board,
    &.screenshot-board,
    &.inquiry {
      width: 100% !important;
      height: inherit !important;
      @include flex(flex, $display-flex-row-wrap !important, center !important, flex-start !important);
      padding: 16px 0 !important;
      padding-bottom: 16px !important;
      border-bottom: 1px solid $neutral-200 !important;

      >img {
        display: none !important;
      }

      >.category {
        display: none !important;
      }

      >.title_box {
        flex: inherit !important;
        width: 100% !important;
        margin-bottom: 4px !important;

        >.title {
          padding-left: 0 !important;
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }

      >.data_box {
        flex: inherit !important;
        margin-right: 16px !important;
        padding: 0 !important;
        width: initial !important;

        .like,
        .view {
          display: none !important;
        }
      }

      >.date {
        flex: inherit !important;
        display: flex !important;
        width: initial !important;
        margin: 0 !important;
      }

      &:hover {

        // border: 1px solid transparent;
        &::after {
          display: none;
        }
      }

      &.inquiry {
        border: none !important;
        border-bottom: 1px solid $neutral-200 !important;

        .title_box {
          .title {
            text-indent: 0;
          }

          &::after {
            display: none;
          }
        }

        >.data_box {
          display: flex;
        }
      }

      &.screenshot-board {
        border: 1px solid transparent !important;
        border-bottom: 1px solid $neutral-200 !important;
      }
    }

    &.fixed {
      >.data_box {
        display: flex;
      }
    }
  }
}

@media (max-width: 1402px) {
  .board_list {
    >.title_box {
      flex: 7;
    }

    >.date {
      flex: 1;
    }

    &.padding {
      padding: 16px;
    }

    &.event,
    &.screenshot-board {
      padding: 0;

      &.event {
        width: 348px;
        height: 252px;

        >img {
          height: 174px;
        }

        >p {

          &:last-of-type,
          &:first-of-type {
            margin-left: 8px;
          }
        }

        &:hover {
          &::after {
            height: 174px;
          }
        }
      }

      &.screenshot-board {
        &.screenshot-board {
          width: 348px;
          height: 246px;
          padding-bottom: 5px;

          >img {
            height: 174px;
          }

          &:hover {
            &::after {
              height: 174px;
            }
          }
        }
      }
    }

    &.detail {
      &.event {
        width: 100%;
        height: inherit;
        margin-bottom: 0;
        padding: 16px 0;
        border-bottom: 1px solid $neutral-200 !important;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.category {
          margin-left: 0 !important;
          margin-right: 4px;
        }

        >img {
          display: none;
        }

        >.title_box {
          // flex: inherit;
        }

        >.date {
          margin-right: 0;
          width: auto !important;

          >span {
            display: none;
          }
        }

        &:hover {
          border: 1px solid transparent !important;
          border-bottom: 1px solid $neutral-200 !important;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .freeboard {
    .board_list {
      position: relative;
      @include flex(flex, $display-flex-row-wrap, center, flex-start);

      >.title_box {
        position: inherit;
        top: inherit;
        flex: inherit;
        width: 70%;
      }

      >.date {
        position: absolute;
        top: calc(50% - 9px);
        right: 15px;
        flex: inherit;
      }

      >.data_box {
        flex: inherit;
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, flex-start);

        >.data_user {
          flex: inherit;
          margin-right: 6px;
        }

        >.like,
        >.view {
          flex: inherit;
          margin-right: 6px;
        }
      }

      &.notice,
      &.event,
      &.free-board,
      &.tip-board,
      &.screenshot-board {
        padding: 12px 15px;
        cursor: pointer;
      }

      &.free-board,
      &.tip-board {
        &.fixed {
          >.data_box {
            display: none;
          }
        }
      }
    }
  }


}

@media (max-width: 760px) {
  .board_list {
    position: relative;
    height: 74px;

    >.category {
      position: absolute;
      top: 12px;
      margin-right: 0;
    }

    >.title_box {
      position: relative;
      top: 16px;
      flex: 7;
      margin-right: 14px;
    }

    >.date {
      flex: 1;
    }

    &.fixed {
      background: $neutral-50;

      >.title_box {
        position: relative;
        text-indent: 20px;
        bottom: inherit;
        top: 12px;

        &::after {
          top: calc(50% - 8px);
          width: 16px;
          height: 16px;
        }
      }
    }

    &.notice {
      padding: 30px 0;

      >.title_box {
        top: 12px;
      }
    }

    &.event,
    &.screenshot-board {

      &.event,
      &.screenshot-board {
        width: 100% !important;
        height: 247px;
        padding: 0;

        >.category {
          position: inherit;
          top: inherit;
        }

        >img {
          height: 165px;
        }

        >.title_box {
          position: inherit;
          top: inherit;

        }

        .date {
          letter-spacing: -0.25px;
        }

        &:hover {
          &::after {
            height: 165px;
          }
        }
      }
    }

    &.detail {
      flex-flow: row wrap;
      justify-content: flex-start;
      height: inherit;

      >.category {
        flex: inherit;
        position: inherit;
        top: inherit;
      }

      >.title_box {
        position: inherit;
        flex: inherit;
        top: inherit;
        left: inherit;
        bottom: inherit;
      }

      >.date {
        position: inherit;
        width: 100%;
        flex: inherit;
        text-align: left;
        margin-top: 8px;
      }

      &.event {
        flex-flow: row wrap;
        justify-content: flex-start;
        height: inherit;

        >.date {
          margin: 0 !important;
          width: 100% !important;
        }
        > .category, .title_box {
          margin-bottom: 8px;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  .freeboard {
    .board_list {
      &.fixed {
        >.title_box {
          top: inherit;

          >.title {
            width: 80%;
          }
        }
      }
    }
  }
}