// studio.scss

@import '../../../assets/css/foundation.scss';

.studio {
  width: 100%;

  >.studio__inner {
    width: 100%;

    .title {
      @include NanumSquare-display-45UB($font-color: $neutral-800, $align: center);
      margin-bottom: 20px;
    }

    .description {
      @include NanumSquare-body-22B($font-color: $neutral-500, $align: center);
      margin-bottom: 60px;
    }

    >.content1 {
      width: 100%;
      height: 888px;
      background: url(../../../assets/images/studio/content1.png) no-repeat 50% 50%;
      background-size: cover;
      @include flex(flex, $display-flex-column-no, center, center);

      >.content1__inner {
        width: 1170px;
        @include flex(flex, $display-flex-column-no, flex-start, center);

        >.title {
          @include NanumSquare-display-45UB($font-color: $white, $align: left);
        }

        >.description {
          @include NanumSquare-body-22B($font-color: $white, $align: left);
          margin-bottom: 60px;
          margin-bottom: 36px;
        }
      }
    }

    >.content2 {
      width: 100%;
      padding: 156px 0 221px;
      @include flex(flex, $display-flex-column-no, center, center);

      >.content2__inner {
        width: 1170px;
        @include flex(flex, $display-flex-column-no, center, center);

        >.title {
          color: $neutral-900;
        }

        >.description {
          color: $neutral-600;
          margin: 14px 0 60px;
        }

        >.boxes {
          width: 100%;
          @include flex(flex, $display-flex-row-no, flex-start, space-between);
          gap: 30px;

          >.box {
            position: relative;
            flex: 1;
            height: 346px;
            @include flex(flex, $display-flex-column-no, center, flex-start);
            padding: 45px;
            border-radius: 20px;
            background-color: $white;
            filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.12));

            >.title {
              @include NanumSquare-headline-32UB($font-color: $primary-700, $align: center);
            }

            >.description {
              @include NanumSquare-label-18UB($font-color: $neutral-600, $align: center);
            }

            >.image {
              position: absolute;
              bottom: -60px;
            }

            &:nth-of-type(1) {
              >.image {
                width: 261px;
                height: 241px;
                background: url(../../../assets/images/zemiverse_2306/content4_img1.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(2) {
              >.image {
                width: 299px;
                height: 299px;
                background: url(../../../assets/images/zemiverse_2306/content4_img2.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(3) {
              >.image {
                width: 255px;
                height: 255px;
                background: url(../../../assets/images/zemiverse_2306/content4_img3.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    >.content3 {
      position: relative;
      width: 100%;
      height: 1080px;
      // background: url(../../../assets/images/studio/content3_img.png) no-repeat 50% 100%, radial-gradient(41.20% 41.20% at 50.00% 50.00%, rgba(255, 255, 255, 0.48) 0%, rgba(154, 75, 255, 0.48) 100%), #F6E2FF;
      background: url(../../../assets/images/studio/content3_img.png) no-repeat 50% 100%, #F6E2FF;

      &::after,
      &::before {
        z-index: 15;
        position: absolute;
        content: "";
      }

      &::after {
        top: -64px;
        left: 8%;
        width: 341px;
        height: 483px;
        background: url(../../../assets/images/studio/content3_img1.png) no-repeat 50% 50%;
        background-size: contain;
      }

      &::before {
        top: 139px;
        right: 9%;
        width: 259px;
        height: 188px;
        background: url(../../../assets/images/studio/content3_img2.png) no-repeat 50% 50%;
        background-size: contain;
      }

      >.content3__inner {
        z-index: 20;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 153px;
        @include flex(flex, $display-flex-column-no, center, flex-start);

        &::after,
        &::before {
          z-index: 25;
          position: absolute;
          content: "";
        }

        &::after {
          bottom: 41px;
          left: 5%;
          width: 296px;
          height: 213px;
          background: url(../../../assets/images/studio/content3_img3.png) no-repeat 50% 50%;
          background-size: contain;
        }

        &::before {
          bottom: 140px;
          right: 12%;
          width: 207px;
          height: 268px;
          background: url(../../../assets/images/studio/content3_img4.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }

    >.content4 {
      width: 100%;
      padding: 170px 0;
      @include flex(flex, $display-flex-column-no, center, center);

      >.content4__inner {
        width: 1170px;
        @include flex(flex, $display-flex-row-no, center, space-between);
        gap: 24px;

        >.left {
          width: 515px;
          height: 507px;
          background: url(../../../assets/images/studio/content4_img.png) no-repeat 50% 50%;
          background-size: contain;
        }

        >.right {
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >p {
            text-align: left;

            &.description {
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    >.content5 {
      width: 100%;
      padding: 155px 0 220px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(158, 119, 237, 0.02) 100%), #F4F5F8;
      @include flex(flex, $display-flex-column-no, center, center);

      >.content5__inner {
        width: 1170px;
        @include flex(flex, $display-flex-column-no, center, center);

        >.boxes {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);
          gap: 30px;

          >.box {
            flex: 1;
            min-height: 346px;
            max-height: 346px;
            background: $white;
            border-radius: 20px;
            border: 1px solid var(--neutral-neutral-100, #F2F4F7);
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12);
            padding: 51px 24px 24px;
            @include flex(flex, $display-flex-column-no, center, flex-start);

            >.img {
              width: 162px;
              height: 162px;
              margin-bottom: 25px;
            }

            >.box__inner {
              @include flex(flex, $display-flex-column-no, center, flex-start);

              >.title {
                @include NanumSquare-title-22UB($font-color: $primary-700, $align: center);
                margin-bottom: 4px;
              }

              >.description {
                @include NanumSquare-label-16UB($font-color: $neutral-600, $align: center);
                margin-bottom: 0;
              }
            }

            &:nth-of-type(1) {
              >.img {
                background: url(../../../assets/images/studio/content5_img1.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(2) {
              >.img {
                background: url(../../../assets/images/studio/content5_img2.png) no-repeat 100% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(3) {
              >.img {
                background: url(../../../assets/images/studio/content5_img3.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(4) {
              >.img {
                background: url(../../../assets/images/studio/content5_img4.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(5) {
              >.img {
                background: url(../../../assets/images/studio/content5_img5.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    >.content6 {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      margin: 130px 0 60px;

      >.normal_button {
        padding: 16px 45px;
      }
    }

    >.content7 {
      margin-bottom: 130px;
      @include flex(flex, $display-flex-column-no, center, center);

      >.content7__inner {
        width: 1170px;
        padding: 80px 120px;
        background: $neutral-50;
        border-radius: 52px;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.box {
          @include flex(flex, $display-flex-column-no, center, center);
          max-width: 184px;

          >.normal_button {
            margin-bottom: 24px;
            cursor: default;
          }

          >.title {
            @include NanumSquare-title-22UB($font-color: $neutral-900, $align: center);
            margin-bottom: 8px;
          }

          >.description {
            @include NanumSquare-body-16UB($font-color: $neutral-600, $align: center);
            margin-bottom: 0;
          }
        }

        >.arrow {
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19M19 12L12 5M19 12L12 19' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .studio {

    >.studio__inner {

      .title {
        @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
        margin-bottom: 15px;
      }

      .description {
        @include NanumSquare-body-18($font-color: $neutral-400, $align: center);
        margin-bottom: 30px;
      }

      >.content1 {
        background: url(../../../assets/images/studio/content1_tablet.png) no-repeat 50% 50%;
        background-size: cover;
        @include flex(flex, $display-flex-column-no, center, flex-start);
        padding: 138px 0;

        >.content1__inner {
          width: 720px;

          >.title {
            @include NanumSquare-display-48UB($font-color: $white, $align: left);
          }

          >.description {
            @include NanumSquare-headline-24($font-color: $white, $align: left);
            margin-bottom: 36px;
          }
        }
      }

      >.content2 {
        padding: 81px 24px 161px;

        >.content2__inner {
          width: 100%;
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >.title {
            width: calc(50% - 15px);
            text-align: left;
            margin-bottom: 14px;
          }

          >.description {
            width: calc(50% - 15px);
            text-align: left;
            margin: 14px 0 150px;
          }

          >.boxes {
            position: relative;

            >.box {

              &:nth-of-type(1) {
                position: absolute;
                top: -428px;
                right: 0;
                width: calc(50% - 15px);
              }
            }
          }
        }
      }

      >.content3 {
        height: 754px;
        background: url(../../../assets/images/studio/content3_img.png) no-repeat 50% 100%, #F6E2FF;
        background-size: 1300px 460px;

        &::after {
          top: 33px;
          left: -40px;
          width: 158px;
          height: 224px;
        }

        &::before {
          top: 34px;
          right: 20px;
          width: 120px;
          height: 87px;
        }

        >.content3__inner {
          padding: 134px 0 0 0;

          >.description {
            color: $neutral-500;
          }

          &::after {
            bottom: 49px;
            left: 80px;
            width: 138px;
            height: 98px;
          }

          &::before {
            bottom: 95px;
            right: 140px;
            width: 96px;
            height: 125px;
          }
        }
      }

      >.content4 {
        padding: 150px 0;

        >.content4__inner {
          width: 720px;

          >.left {
            width: 345px;
            height: 300px;
          }
        }
      }

      >.content5 {
        padding: 150px 0 180px;

        >.content5__inner {
          width: 720px;

          >.boxes {
            @include flex(flex, $display-flex-row-wrap, center, space-between);
            gap: 30px;

            >.box {
              min-height: 320px;
              max-height: 320px;
              padding: 51px 24px 24px;
              @include flex(flex, $display-flex-column-no, center, flex-start);

              >.img {
                width: 162px;
                height: 162px;
                margin-bottom: 25px;
              }

              &:nth-of-type(1) {
                min-width: calc(50% - 15px);
              }

              &:nth-of-type(2) {
                min-width: calc(50% - 15px);
              }
            }
          }
        }
      }

      >.content6 {
        >.normal_button {
          padding: 10px 47.5px;
        }
      }

      >.content7 {
        width: 100%;
        margin-bottom: 120px;
        padding: 0 24px;

        >.content7__inner {
          width: 100%;
          padding: 60px 38.5px;

          >.box {
            max-width: 164px;

            >.title {
              @include NanumSquare-label-16UB($font-color: $neutral-900, $align: center);
            }

            >.description {
              @include NanumSquare-body-14($font-color: $neutral-600, $align: center);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .studio {

    >.studio__inner {

      .title {
        @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: center);
        margin-bottom: 15px;
      }

      .description {
        @include NanumSquare-body-14($font-color: $neutral-400, $align: center);
        margin-bottom: 30px;
      }

      >.content1 {
        height: 610px;
        background: url(../../../assets/images/studio/content1_mobile.png) no-repeat 50% 50%;
        background-size: cover;
        @include flex(flex, $display-flex-column-no, center, flex-start);
        padding: 138px 36px 0 36px;

        >.content1__inner {
          width: 100%;

          >.title {
            @include NanumSquare-headline-36($font-color: $white, $align: left);
            margin-bottom: 0;
          }

          >.description {
            @include NanumSquare-display-16($font-color: $white, $align: left);
            margin-bottom: 24px;
          }
        }
      }

      >.content2 {
        padding: 81px 24px 160px;

        >.content2__inner {

          >.title {
            width: 100%;
          }

          >.description {
            width: 100%;
            margin: 14px 0 30px;
          }

          >.boxes {
            @include flex(flex, $display-flex-column-no, center, center);
            gap: 120px;

            >.box {
              width: 100%;
              min-height: 346px;


              &:nth-of-type(1) {
                position: inherit;
                top: inherit;
                right: inherit;
                width: 100%;
              }
            }
          }
        }
      }

      >.content3 {
        background: url(../../../assets/images/studio/content3_img.png) no-repeat 55% 110%, #F6E2FF;
        background-size: 1800px 590px;

        &::after {
          top: 10px;
          left: 13px;
          width: 90px;
          height: 127px;
        }

        &::before {
          top: 70px;
          right: 33px;
          width: 68px;
          height: 49px;
        }

        >.content3__inner {
          padding: 134px 15px 0 15px;

          >p {
            width: 100%;
          }

          >.title {
            margin-bottom: 30px;
          }

          >.description {
            color: $neutral-500;
          }

          &::after {
            bottom: 44px;
            left: 68px;
            width: 138px;
            height: 98px;
          }

          &::before {
            bottom: 116px;
            right: 18px;
            width: 96px;
            height: 125px;
          }
        }
      }

      >.content4 {
        padding: 50px 0 90px;

        >.content4__inner {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          gap: 30px;

          >.left {
            width: 330px;
            height: 300px;
          }

          >.right {
            @include flex(flex, $display-flex-column-no, center, center);

            >p {
              text-align: center;
            }
          }
        }
      }

      >.content5 {
        padding: 90px 0;

        >.content5__inner {
          width: 100%;
          padding: 0 15px;

          >.boxes {
            @include flex(flex, $display-flex-column-no, center, center);
            gap: 30px;

            >.box {
              width: 100%;
              min-height: 320px;
              max-height: 320px;
              padding: 51px 24px 24px;
              @include flex(flex, $display-flex-column-no, center, flex-start);

              >.img {
                width: 162px;
                height: 162px;
                margin-bottom: 25px;
              }

              &:nth-of-type(1),
              &:nth-of-type(2) {
                min-width: inherit;
              }
            }
          }
        }
      }

      >.content6 {
        >.text__box {
          >.description {
            margin-bottom: 24px;
          }
        }

        >.content6 {
          >.normal_button {
            padding: 12px 51px;
          }
        }
      }

      >.content7 {
        margin-bottom: 80px;
        padding: 0 15px;

        >.content7__inner {
          padding: 56px 72.5px;
          @include flex(flex, $display-flex-column-no, center, center);
          gap: 24px;

          >.box {
            max-width: 164px;

            >.normal_button {
              margin-bottom: 24px;
            }

            >.title {
              @include NanumSquare-label-16UB($font-color: $neutral-900, $align: center);
            }

            >.description {
              @include NanumSquare-body-14($font-color: $neutral-600, $align: center);
            }
          }

          >.arrow {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}