// modal

@import '../../assets/css/foundation.scss';

.dim {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);

  .modal {
    @include flex(flex, $display-flex-column-no, center, center);
    position: absolute;
    padding: 24px;
    background: $white;
    border-radius: 12px;

    >.icon_box {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-bottom: 20px;
      background: $error-100;
      border: 8px solid $error-50;

      >.icon {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: inherit;
      }
    }

    >.title {
      margin-bottom: 8px;
      @include NanumSquare-title-18UB($font-color: $neutral-900);
    }

    >.description {
      margin-bottom: 32px;
      @include NanumSquare-body-14($font-color: $neutral-500);
    }

    .button_box {
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, center);

      .normal_button {
        flex: 1;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    &.confirm {
      top: calc(50% - 10%);
      left: calc(50% - 200px);
      width: 400px;

      &.warning {
        >.icon_box {
          background: $warning-100;
          border: 8px solid $warning-50;

          >.icon {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z' stroke='%23DC6803' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &.danger {
        >.icon_box {
          background: $error-100;
          border: 8px solid $error-50;

          >.icon {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%23D92D20' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &.success {
        >.icon_box {
          background: $success-100;
          border: 8px solid $success-50;

          >.icon {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01' stroke='%23039855' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
        }
      }
    }

    &.report {
      top: calc(50% - 25%);
      left: calc(50% - 265px);
      width: 530px;
      padding: 48px 32px;

      >.close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        cursor: pointer;

        &::after {
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
          content: "";
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }

      .page_top {
        width: 100%;
        border-bottom: 1px solid $neutral-200;

        >.title {
          @include NanumSquare-title-18UB($font-color: $neutral-700);
        }
      }

      >.description {
        width: 100%;
        padding: 24px 0;
        @include NanumSquare-body-12($font-color: $neutral-700);
        margin: 0;
      }

      >table {
        width: 100%;
        border-top: 1px solid $neutral-200;
        margin-bottom: 24px;

        >tbody {
          width: 100%;

          >tr {
            width: 100%;
            border-bottom: 1px solid $neutral-200;

            >th {
              width: 130px;
              background: $neutral-50;
              @include NanumSquare-label-12UB($font-color: $neutral-700, $align: center);
              padding: 16px 0;
              box-sizing: border-box;
            }

            >td {
              padding: 16px 0 16px 16px;
              @include NanumSquare-label-12UB($font-color: $neutral-600);
              box-sizing: border-box;
            }

            &:last-of-type {
              >td {
                padding: 0;
                height: 94px;

                >textarea {
                  width: 100%;
                  height: 100%;
                  border: none;
                  background: none;
                  @include NanumSquare-body-12($font-color: $neutral-600);
                  padding: 16px;
                  box-sizing: border-box;

                  &::placeholder {
                    color: $neutral-300;
                  }

                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }

      >.button_box {
        width: auto;
      }
    }

    &.alert {
      top: calc(50% - 5%);
      left: calc(50% - 265px);
      width: 530px;
      padding: 18px 17px;
      @include flex(flex, $display-flex-row-no, center, space-between);
      > .left {
        @include flex(flex, $display-flex-row-no, center, center);
        > svg {
          margin-right: 6px;
          > path {
            stroke: $info-500;
          }
        }
        > .description {
          @include NanumSquare-title-18UB($font-color: $neutral-700);
        }
      }
      > .close_button {
        padding: 6px;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
        cursor: pointer;
        &:hover {
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%23344054' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
        }
      }
    }
  }
  &.studio_item {
    z-index: 100;
    background: $white;
    @include flex(flex, $display-flex-column-no, center, flex-start);
    overflow: auto;
    
    .modal {
      width: 1170px;
      border-radius: 0;
      @include flex(flex, $display-flex-column-no, flex-start, center);
      padding: 56px 0 120px 0;
      > .title__box {
       width: 100%; 
       border-bottom: 1px solid $neutral-200;
       margin-bottom: 24px;
       > .title {
          @include NanumSquare-title-18UB($font-color: $neutral-700);
          padding-bottom: 16px;
        > span {
          position: relative;
          @include NanumSquare-title-18UB($font-color: $neutral-300);
          margin-left: 24px;
          &::after {
            position: absolute;
            top: calc(50% - 12px);
            left: -24px;
            content: "";
            width: 24px;
            height: 24px;
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 18L15 12L9 6' stroke='%23101828' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          }
        }
       }
      }

      > .list__check {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, flex-start);
        > .check {
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 6L9 17L4 12' stroke='%2312B76A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
        > .description {
          @include NanumSquare-label-14($font-color: $neutral-700);
        }
      }

      > .normal_button.guide {
        position: absolute;
        top: 145px;
        right: 0;
      }

      > .item__register__box {
        width: 100%;
        height: 667px;
        @include flex(flex, $display-flex-row-no, center, space-between);
        border: 1px solid $neutral-200;
        border-radius: 18px;
        overflow: hidden;
        margin-top: 16px;
        > .left {
          position: relative;
          width: 420px;
          height: 100%;
          border-right: 1px solid $neutral-200;
          > .loading {
            position: absolute;
            top: calc(50% - 18px);
            left: calc(50% - 18px);
          }
          > canvas {
            width: 100%;
            height: 100%;
          }
          > .button--unity--fullscreen {
            position: absolute;
            bottom: 24px;
            right: 24px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $neutral-100 url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 3H21M21 3V9M21 3L14 10M9 21H3M3 21V15M3 21L10 14' stroke='%23101828' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
            cursor: pointer;
            &:hover {
              background: $neutral-200 url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 3H21M21 3V9M21 3L14 10M9 21H3M3 21V15M3 21L10 14' stroke='%23101828' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
            }
          }
        }
        > .right {
          width: calc(100% - 420px);
          height: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          > .top {
            width: 100%;
            padding-right: 16px;
            margin-bottom: 24px;
            @include flex(flex, $display-flex-row-no, center, space-between);
          }
          > .middle {
            position: relative;
            width: 496px;
            height: 496px;
            margin-bottom: 8px;
            > img {
              width: 100%;
              height: 100%;
            }
            > .uv {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              // background: url(../../assets/images/uv_example.png) no-repeat 50% 50%;
              // background-size: contain;
            }
          }
          > .alert {
            width: 496px;
            margin-bottom: 24px;
            @include NanumSquare-label-11($font-color: $neutral-600);
          }
          > .button--upload {
            > .button {
              padding: 10px 16px 10px 38px;
              background: $primary-600 url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M17 8L12 3M12 3L7 8M12 3V15' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 16px 50%;
              border-radius: 10px;
              @include NanumSquare-label-16UB($font-color: $white);
              cursor: pointer;
              &:hover {
                background: $primary-700 url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M17 8L12 3M12 3L7 8M12 3V15' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 16px 50%;
              }
            }
            > .hide {
              display: none;
            }
          }
        }
      }

      > .normal_button.next {
        position: absolute;
        bottom: 56px;
        left: calc(50% - 45.25px);
      }

      > .close_button {
        position: fixed;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
        cursor: pointer;
      }
    }
  }
  &.search__modal {
    background: white;
    padding: 16px 15px;
    > .top {
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, space-between);
      > .box {
        position: relative;
        width: 100%;
        > .delete__searchvalue {
          display: none;
          position: absolute;
          top: 5px;
          right: 10px;
          width: 40px;
          height: 40px;
          background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
          cursor: pointer;
          &.on {
            display: block;
          }
        }
      }
    }
    > .bottom {
      width: 100%;
      border-bottom: 1px solid $neutral-200;
      > .top {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        padding: 18px 0;
        > .title {
          @include NanumSquare-label-12UB($font-color: $neutral-700);
        }
      }
      > .box {
        width: 100%;
        > .no_list {
          width: 100%;
          @include NanumSquare-label-12UB($font-color: $neutral-400, $align: center);
          padding: 86px 0;
        }
        > .search__list {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);
          padding: 10px 0;
          > .start {
            @include flex(flex, $display-flex-row-no, center, center);
            cursor: pointer;
            > .icon {
              width: 16px;
              height: 16px;
              background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
              margin-right: 13px;
            }
            > p {
              @include NanumSquare-body-14($font-color: $neutral-700);
            }
          }
          > .delete__button {
            width: 24px;
            height: 24px;
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%23D0D5DD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
            margin-right: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 1402px) {
  .dim {

    .modal {
      &.alert {
        width: 400px;
        left: calc(50% - 200px);
      }
    }
    &.studio_item {
      width: 100%;
      height: 100%;
      overflow: auto;
      justify-content: flex-start;
      .modal {
        width: 704px;
        padding: 56px 0 100px 0;
  
        > .normal_button.guide {
          top: 150px;
          right: 0;
        }
  
        > .item__register__box {
          height: 539px;
          margin-bottom: 16px;
          > .left {
            width: 256px;
          }
          > .right {
            width: calc(100% - 256px);
            > .middle {
              width: 354px;
              height: 354px;
            }
            > .alert {
              width: 354px;
              @include NanumSquare-label-11($font-color: $neutral-600, $align: center);
            }
          }
        }

        > .normal_button.next {
          bottom: 60px;
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .dim {

    .modal {

      >.button_box {
        width: 100% !important;
        flex-flow: column nowrap;

        > .normal_button {
          width: 100%;

          &:first-of-type {
            margin-right: 0;
            margin-bottom: 8px;
          }
        }
      }

      &.report {
        top: 0;
        left: 0;
        padding: 48px 15px;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        border-radius: 0;
      }
      &.alert {
        width: 330px;
        left: calc(50% - 165px);
      }
    }

    &.studio_item {
      width: 100%;
      height: 100%;
      overflow: auto;
      justify-content: flex-start;
      .modal {
        width: 330px;
        // height: 1500px;
        padding: 56px 0 100px 0;

        > .title__box {
          > .title {
              @include NanumSquare-title-16UB($font-color: $neutral-700);
            > span {
              @include NanumSquare-title-16UB($font-color: $neutral-300);
            }
          }
        }

        > .list__check {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, flex-start);
          > .check {
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 6L9 17L4 12' stroke='%2312B76A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            margin-right: 4px;
          }
          > .description {
            @include NanumSquare-label-12UB($font-color: $neutral-700);
          }
          &:nth-of-type(3) {
            padding-bottom: 45px;
          }
        }
  
        > .normal_button.guide {
          // position: static;
          top: 150px;
          left: 0;
          margin-top: 24px;
        }
  
        > .item__register__box {
          height: 975px;
          margin-bottom: 0;
          @include flex(flex, $display-flex-column-re-no, center, center);

          > .left {
            width: 100%;
            height: 503px;
            border-right: none;
            border-top: 1px solid $neutral-200;
          }
          > .right {
            width: 100%;
            height: calc(100% - 503px);
            > .top {
              margin-bottom: 16px;
            }
            > .middle {
              width: 296px;
              height: 296px;
            }
            > .alert {
              width: 100%;
              @include NanumSquare-label-11($font-color: $neutral-600, $align: center);
            }
          }
        }
        > .normal_button.next {
          bottom: 48px;
        }
      }
      
    }
  }
}