@import '../../assets/css/foundation.scss';

.content.register{
  width: 100%;
  min-height: calc(100vh - 75px);
  // padding: 48px 375px;
  &.register {
    @include flex(flex, $display-flex-column-no, center, center);
  }
}

.member {
  width: 100%; 
  @include flex(flex, $display-flex-column-no, center, center);
  &__base {
    width: 550px;
    border: 1px solid $neutral-200;
    border-radius: 25px;
    background-color: $white;
    padding: 72px 99px;
    box-sizing: border-box;
    @include flex(flex, $display-flex-column-no, center, center);
  }
  &__wrapper {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, space-between);
    gap: 24px;
    // 나중에 변경 예정
    > button {
      padding: 10px 18px;
      width: 160px;
      height: 40px;
      background-color: $primary-200;
      border: 1px solid $primary-200;
      border-radius: 30px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      @include NanumSquare-label-14B($font-color: $white, $align: center);
    }
  }

  &__container {
    width: 100%;
    gap:24px;
    @include flex(flex, $display-flex-column-no, center, space-between);
  }

  
  &__content {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, space-between);
    gap: 24px;
  }
  &__header {
    @include flex(flex, $display-flex-column-no, center, space-between);
    gap:8px;
    > p {
      &:first-child {
        @include NanumSquare-headline-32UB($font-color: $primary-600);
      }
      &:last-child {
        @include NanumSquare-label-18UB($font-color: $info-500, $align: center);
      }
    }
  }
  
  &__footer {
    @include flex(flex, $display-flex-column-no, flex-start, space-between);
    gap: 12px;
  }
  &__error {
    @include NanumSquare-label-12B($font-color:$error-500);
  }
}

.nickname {
  &__container {
    width: 100%;
    @include flex(flex, $display-flex-column-no, flex-start, center);
    gap: 16px;
  }
  &__explanation {
    @include flex(flex, $display-flex-column-no, flex-start, flex-start);
    > p {
      @include NanumSquare-body-12($font-color: $neutral-600);
      // white-space: nowrap;
    }
  }
}


@media screen and (max-width: 1402px) {
  .content.register{
    width: 100%;
    margin-left: 0;
    min-height: calc(100vh - 144px);
  }
}

@media screen and (max-width: 760px) {
  .content.register{
    min-height: calc(100vh - 155px);
  }
  .member{
    &__base {
      width: 360px;
      padding: 0 15px;
      border: none;
    }
    &__header {
      > p {
        &:first-child {
          @include NanumSquare-headline-28UB($font-color:$primary-600, $align: center);
        }
        &:last-child {
          @include NanumSquare-label-14B($font-color: $info-500, $align: center);
        }
      }
    }
  }
}