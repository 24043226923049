@import '../../../../assets/css/foundation.scss';

.avatar {
  &__container {
    @include flex(flex, $display-flex-column-no, flex-start, center);
    gap: 16px;
  }
  &__wrapper {
    @include flex(flex, $display-flex-column-no, flex-start, center);
    gap:8px;
  }
  &__label {
    @include NanumSquare-label-12UB($font-color: $neutral-900);
  }
  &__explanation {
    @include flex(flex, $display-flex-column-no, flex-start, flex-start);
    > p {
      @include NanumSquare-body-12($font-color: $neutral-600);
    }
  }
}