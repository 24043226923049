@import '../../../../assets/css/foundation.scss';

.sideNav {
  width: 100%;
  // height: 100%;
  @include flex(flex, $display-flex-row-no, flex-start, center);
  // padding-top: 20px;

  ul,
  li {
    box-sizing: border-box;
  }

  >.zemiverse_tab,
  >.studio_tab {
    // height: 100%;
    .nav_ul_1deps {
      // height: 100%;
      @include flex(flex, $display-flex-row-no, flex-start, center);

      >li:first-of-type {
        // display: none;
      }

      >.nav_li_1deps {
        cursor: pointer;
        margin-right: 72px;

        // height: 64px;

        >p {
          height: 100%;
          padding: 20px 0;
          box-sizing: border-box;
          @include NanumSquare-label-16UB($font-color: $white, $align: left);
          white-space: nowrap;
          
        }

        &.on {
          position: relative;

          >p {
            &::after {
              position: absolute;
              left: 0;
              top: 45px;
              content: "";
              width: 100%;
              max-width: 61.08px;
              height: 2px;
              background: $white;
            }
          }
        }

        &:last-of-type {
          margin-right: 0;
          border: none;
        }

        >.nav_ul_2deps {
          padding: 14px 0;
          // @include flex(flex, $display-flex-column-no, flex-start, center);
          display: none;
          gap: 24px;

          >.nav_li_2deps {
            cursor: pointer;
            

            p {
              @include NanumSquare-label-12UB($font-color: $white, $align: left);
              white-space: nowrap;
            }

            &.on {
              position: relative;

              >p {
                &::after {
                  position: absolute;
                  left: 0;
                  top: 15px;
                  content: "";
                  width: 100%;
                  height: 1px;
                  background: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  &.on {

    >.zemiverse_tab,
    >.studio_tab {
      .nav_ul_1deps {

        >.nav_li_1deps {
          &:last-of-type {
            // position: relative;
            // left: -21.53px;
          }

          >.nav_ul_2deps {
            @include flex(flex, $display-flex-column-no, flex-start, center);
            
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .sideNav {
    z-index: 35;
    position: absolute;
    top: 56px;
    left: 0;
    width: 233px;
    height: calc(100vh - 56px);
    display: none;
    padding: 16px 0;
    box-sizing: border-box;
    box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.05), inset 0px 5px 5px rgba(0, 0, 0, 0.03);
    overflow-y: scroll;
    background: $neutral-50;


    >.zemiverse_tab,
    >.studio_tab {
      width: 100%;

      .nav_ul_1deps {
        @include flex(flex, $display-flex-column-no, flex-start, flex-start);

        >.nav_li_1deps {
          position: relative;
          width: 100%;
          height: auto;
          margin-right: 0;

          >p {
            @include NanumSquare-label-18UB($font-color: $neutral-500, $align: left);
            text-indent: 16px;
            line-height: 56px;
            padding: 0;
            height: inherit;
          }

          &:hover {
            background: $white;
          }

          &.on {

            >p {
              position: relative;
              color: $primary-700;

              &::after {
                display: none;
              }

              &::before {
                content: "";
                position: absolute;
                top: calc(50% - 12px);
                left: 0;
                width: 3px;
                height: 24px;
                border-radius: 0px 2px 2px 0px;
                background: $primary-700;
              }
            }
          }

          >.nav_ul_2deps {
            width: 100%;
            position: inherit;
            gap: 0;
            margin: 0;
            background: $neutral-50;

            >.nav_li_2deps {
              width: 100%;
              padding: 0;

              p {
                @include NanumSquare-title-16UB($font-color: $neutral-600, $align: left);
                line-height: 48px;
                text-indent: 48px;
              }

              &:hover {
                background: $white;
              }

              &.on {

                >p {
                  color: $primary-700;

                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

          &.on {
            @include flex(flex, $display-flex-column-no, flex-start, center);
          }

          &:nth-last-of-type(1),
          &:nth-last-of-type(2),
          &:nth-last-of-type(3) {
            &::after {
              position: absolute;
              top: 16px;
              right: 16px;
              content: "";
              width: 24px;
              height: 24px;
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
              background-size: contain;
              transform: rotate(180deg);
              transition: all .3s linear 0;
            }

            &.open {
              &::after {
                transform: rotate(0);
              }

              &.close {
                &::after {
                  transform: rotate(180deg);
                }
              }
            }
          }

          &:last-of-type {
            left: 0 !important;
          }
        }
      }
    }

    &.on {
      @include flex(flex, $display-flex-column-no, flex-start, flex-start);
      >.zemiverse_tab,
      >.studio_tab {
        .nav_ul_1deps {

          >.nav_li_1deps {

            >.nav_ul_2deps {
              display: none;
              gap: 0;
              margin: 0;

              &.on {
                @include flex(flex, $display-flex-column-no, flex-start, center);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .sideNav {
    width: 100%;
  }
}