@import '../../../assets/css/foundation.scss';

.select-wrap {
  position: relative;
}

.select {
  &.image {
    cursor: pointer;
    width: 352px;
    background-color: $white;
    border: 1px solid $neutral-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 12px 16px;

    @include flex(flex, $display-flex-row-no, center, space-between);

    >.left {
      @include flex(flex, $display-flex-row-no, center, flex-start);
      gap: 12px;

      >img {
        width: 32px;
        height: 32px;
        border-radius: 200px;
      }

      >p {
        @include NanumSquare-label-14B($font-color: $neutral-700, $align: center);
      }
    }

    >i {
      margin-right: 2px;
      @include image-style(20px, 20px, url('../../../assets/images/chevron-down.png') no-repeat 50% 50%, contain);
    }
  }

  &.classification {
    box-sizing: border-box;
    padding: 10px 16px;
    width: 150px;
    background-color: $white;
    // opacity: 0.8;
    border: 1px solid $neutral-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    @include flex(flex, $display-flex-row-no, center, space-between);
    cursor: pointer;
    &.on {
      border: 1px solid $primary-300;
      > p {
        @include NanumSquare-label-12B($font-color: $neutral-800);
      }
    }
    &.off {
      > p {
        @include NanumSquare-label-12B($font-color: $neutral-500);
      }
    }
    > svg {
      width: 20px;
      height: 20px;
      > path {
        stroke: $neutral-500;
        stroke-width: 2px;
      }
    }
  }
}

.select-ul {
  &.image {
    position: absolute;
    // top: 59;
    // left: 0;
    z-index: 10;
    width: 352px;
    height: 224px;
    overflow: scroll;
    background: $white;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    @include flex(flex, $display-flex-column-no, flex-start, center);
    cursor: pointer;

    >li {
      align-self: stretch;
      padding: 12px 16px;
      @include flex(flex, $display-flex-row-no, center, flex-start);
      gap: 12px;
      &.disabled {
        cursor: default;

        >img {
          filter: grayscale(100%);
        }

        >p {
          @include NanumSquare-label-14B($font-color: $neutral-300);
        }

        &:hover {
          background: $white;
        }
      }
      &:hover {
        background: $neutral-50;
      }
      >img {
        width: 32px;
        height: 32px;
        border-radius: 200px;
      }
      >p {
        @include NanumSquare-label-14B($font-color: $neutral-700);
      }
    }
  }

  &.classification {
    box-sizing: border-box;
    position: absolute;
    background:$white;
    width: 150px;
    // height: 128px;
    border: 1px solid $neutral-100;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    padding: 4px 0;
    overflow-y: scroll;
    cursor: pointer;
    z-index: 100;
    > li {
      @include flex(flex, $display-flex-row-no, center, space-between);
      padding: 10px 14px;
      // gap: 8px;
      > p {
        @include NanumSquare-body-14B($font-color: $neutral-600);
      }
      > svg {
        width: 20px;
        height: 20px;
        > path {
          stroke: $primary-600;
        }
      }
      &.selected {
        background-color: $neutral-50;
      }
    }
  }

  &.on {
    display: block;
  }

  &.off {
    display: none;
  }
}