@import '../../assets/css/foundation.scss';

.content.login {
  width: 100%;
  
  &.login {
    width: 100%;
    min-height: calc((var(--vh, 1vh) * 100) - 153px);

    @include flex(flex, $display-flex-column-no, center, center);
  }
  .login-member {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, center);
    &__base {
      width: 656px;
      min-height: 476px;
      border: 1px solid $neutral-200;
      border-radius: 25px;
      background-color: $white;
      padding: 72px 148px;
      box-sizing: border-box;
      @include flex(flex, $display-flex-column-no, center, space-between);
      gap: 36px;
    }
    &__header {
      // width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      gap: 16px;
    }
    &__title {
      @include NanumSquare-headline-32UB($font-color: $primary-700, $align: center);
    }
    &__description {
      @include NanumSquare-label-16UB($font-color: $neutral-600, $align: center);
    }
  
    &__button {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      gap: 12px;
    }
  }
}

@media (max-width: 1402px) {
  .content.login {
    margin: 0;
    &.login {
      width: 100%;
      min-height: calc((var(--vh, 1vh) * 100) - 202.19px);
  
      @include flex(flex, $display-flex-column-no, center, center);
    }
  }
}

@media (max-width: 760px) {
  .login-member{
    &__base {
      width: 300px;
      padding: 0;
      border: none;
      @include flex(flex, $display-flex-column-no, center, center);
    }
    &__title {
      @include NanumSquare-headline-28UB($font-color: $primary-600, $align: center);
    }
    &__description {
      @include NanumSquare-label-14B($font-color: $neutral-600, $align: center);
    }
  }
}