@import '../../../assets/css/foundation.scss';
.modal-wrapper {
  &.terms {
    width: 100%;
    height: 100%;
    @include flex(flex, $display-flex-column-no, flex-start, flex-start);
    gap: 24px;
    padding: 0;
  }
  &__top {
    @include flex(flex, $display-flex-column-no, center, center);
    width: 100%;
    gap: 24px;
  }
  &__header {
    gap: 23px;
    justify-self: start;
    align-self: flex-start;
    > p {
      @include NanumSquare-label-18UB($font-color: $neutral-700, $align: center);
    }
  }
  &__scrollbar {
    width: 636px;
    height: 400px;
    padding: 8px 20px 8px 8px;
    background-color: $neutral-50;
    overflow-wrap: break-word;
    overflow-y: scroll;
    margin-bottom: 18px;
    &::-webkit-scrollbar {
      display: inline-block;
      // width: 20px !important;
      width: 4px;
      // height: 50px;
    }
    &::-webkit-scrollbar-thumb {
      // width: 4px !important;
      // height: 5%;
      background-color: $neutral-300;
      // background-clip: padding-box;
      border-radius: 27px;
      // width: 4px;
      // border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: $neutral-50;
      // width: 4px;
    }
    > p {
      word-break: break-all;
      @include NanumSquare-body-14($font-color: $neutral-900);
      > .title {
        @include NanumSquare-body-16UB($font-color: $neutral-900);
      }
      > .dot {
        display: inline-block;
        transform: translateY(-2px);
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $neutral-900;
        margin: 0 5px;
      }
      > .depth1 {
        display: inline-block;
        content: "";
        width: 20px;
        height: 10px;
      }
      > .depth2 {
        display: inline-block;
        content: "";
        width: 40px;
        height: 10px;
      }
      > table {
        width: 100%;
        border-top: 1px solid $neutral-200;
        > thead,
        > tbody {
          width: 100%;
          > tr {
            border-bottom: 1px solid $neutral-200;
            th {
              background: $neutral-50;
            }
            th, td {
              border-right: 1px solid $neutral-200;
              @include NanumSquare-body-14($font-color: $neutral-700);
              text-align: center;
              padding: 15px 0;
              &:last-of-type {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
  &__bottom {
    align-self: center;
  }
}

@media screen and (max-width: 1402px) {
  
}

@media screen and (max-width: 760px) {
  .modal-wrapper {
    width: 100%;
    &__scrollbar {
      width: 100%;
    }
  }
}