@import '../../../../assets/css/foundation.scss';

// header
.main_header {
  z-index: 40;
  position: fixed;
  width: 100%;
  // height: 64px;
  // height: 210px;
  @include flex(flex, $display-flex-row-no, flex-start, space-between);
  background: $primary-500;
  padding: 0 32px 15px 32px;
  transition: all .2s linear 0s;

  &.search_modal_open {
    display: none;
  }

  .hamburger_button {
    display: none;
  }

  >.main_header_left {
    @include flex(flex, $display-flex-row-no, flex-start, center);
    height: 100%;

    // logo
    >.logo {
      width: 251px;
      height: 22px;
      background: url(../../../../assets/images/studio/studio_logo.png) no-repeat 50% 50%;
      background-size: contain;
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      margin-right: 100px;
      margin-top: 20px;
    }
    > .sideNav {
      display: none;
    }
  }

  // main header right
  >.main_header_right {
    @include flex(flex, $display-flex-row-re-no, center, space-between);
    gap: 8px;
    padding-top: 15px;

    // my menu
    >.avatar {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background: url(../../../../assets/images/avatar.png) no-repeat 50% 50%;
      background-size: contain;

      >p {
        display: none;
      }

      >.profile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;

        &.none {
          background: url(../../../../assets/images/avatar.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &:hover {
        width: 32px;
        height: 32px;
        border: none;
      }

      >.my_menu_box {
        position: absolute;
        display: none;
        top: calc(100% + 5px);
        right: 0;
        min-width: 240px;
        background: $white;
        border: 1px solid $neutral-200;
        border-radius: 8px;
        cursor: default;

        >.my_menu_top {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, flex-start);
          padding: 12px 16px;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 39px;
            left: 39px;
            width: 10px;
            height: 10px;
            background: $success-500;
            border: 1.5px solid $white;
            border-radius: 50%;
          }

          >.avatar {
            position: relative;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;

            &.none {
              background: url(../../../../assets/images/avatar.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          >.my_menu_top_right {
            >.nickname {
              @include text-style($neutral-700, 14px, 700, 20px, left);
            }

            >.email {
              @include text-style($neutral-500, 11px, 700, 18px, left);
            }
          }
        }

        >.menu {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);

          >li {
            width: 100%;
            padding: 14px 16px;
            box-sizing: border-box;
            // @include NanumSquare-body-14B;
            @include text-style($neutral-700, 14px, 400, 22px, left);
            cursor: pointer;

            &:last-of-type {
              border-top: 1px solid $neutral-200;
            }

            &:hover {
              background: $neutral-50;
            }
          }
        }

        &.on {
          @include flex(flex, $display-flex-column-no, center, center);
        }
      }
    }

    // play button
    .normal_button {
      >.icon {
        &.trailing {
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 18L15 12L9 6' stroke='%236941C6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          background-size: contain;


          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &.hover {
    // background: rgba(158, 119, 237, 0.8);

    >.main_header_left {
      >.sideNav {
        // padding-bottom: 20px;

        >.zemiverse_tab,
        >.studio_tab {
          .nav_ul_1deps {

            >.nav_li_1deps {
              &:last-of-type {
                position: relative;
                // left: -21.53px;
              }

              >.nav_ul_2deps {
                @include flex(flex, $display-flex-column-no, flex-start, center);

              }
            }
          }
        }
      }
    }
  }

  &.main {
    &.top {
      background: none;

      &.hover {
        // background: rgba(158, 119, 237, 0.8);
      }
    }
  }
}

@media (max-width: 1450px) {
  .main_header {
    height: 56px;
    background: #fff;
    padding: 8px;
    border-bottom: 1px solid $neutral-200;

    >.hamburger_button {
      display: block;
      width: 40px;
      height: 40px;
      background: url(../../../../assets/images/icon_hamburger.png) no-repeat 50% 50%;
      background-size: contain;
      cursor: pointer;
      display: none;

      &.on {
        background: url(../../../../assets/images/icon_hamburger_on.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    >.main_header_left {

      // logo
      >.logo {
        position: absolute;
        top: calc(50% - 11px);
        left: calc(50% - 124.5px);
        width: 249px;
        height: 22px;
        background: url(../../../../assets/images/studio/studio_logo_tablet.png) no-repeat 50% 50%;
        background-size: contain;
        margin-right: 0;
        margin-top: 0
      }
    }

    // main header right
    >.main_header_right {
      padding-top: 4px;

      // my menu
      >.avatar {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        // background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='16' fill='%23F2F4F7'/%3E%3Cpath d='M16.0013 14.9999C16.7881 14.9999 17.5571 14.7666 18.2113 14.3295C18.8655 13.8924 19.3753 13.2712 19.6764 12.5443C19.9775 11.8175 20.0562 11.0177 19.9027 10.246C19.7493 9.47439 19.3704 8.7656 18.8141 8.20928C18.2578 7.65297 17.549 7.27411 16.7774 7.12062C16.0057 6.96714 15.2059 7.04591 14.479 7.34699C13.7522 7.64806 13.1309 8.15792 12.6938 8.81208C12.2567 9.46623 12.0234 10.2353 12.0234 11.0221C12.0234 12.0771 12.4425 13.0888 13.1885 13.8348C13.9345 14.5808 14.9463 14.9999 16.0013 14.9999Z' fill='%2398A2B3'/%3E%3Cpath d='M21.9671 24.9557C22.2309 24.9557 22.4838 24.851 22.6703 24.6645C22.8568 24.478 22.9616 24.225 22.9616 23.9613C22.9616 22.115 22.2282 20.3444 20.9227 19.0389C19.6172 17.7334 17.8466 17 16.0003 17C14.1541 17 12.3835 17.7334 11.078 19.0389C9.77248 20.3444 9.03906 22.115 9.03906 23.9613C9.03906 24.225 9.14384 24.478 9.33034 24.6645C9.51683 24.851 9.76978 24.9557 10.0335 24.9557H21.9671Z' fill='%2398A2B3'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;

        >.my_menu_box {
          position: absolute;
          top: calc(100% + 10px);
        }
      }

      // play button
      .normal_button {
        >p {
          display: none;
        }

        >.icon {
          &.trailing {
            background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2309_18823)'%3E%3Cpath d='M16.9589 15.5572H7.26809L18.4607 3.85583C18.4607 3.85583 18.4706 3.84554 18.4755 3.84039C18.899 3.4337 19.1698 2.85712 19.1698 2.21362C19.1698 0.998692 18.2195 0.00512695 17.0574 0.00512695H7.9427C6.78059 0.00512695 5.83023 0.998692 5.83023 2.21362C5.83023 3.42855 6.78059 4.42212 7.9427 4.42212H11.9411L0.93067 15.9279C0.93067 15.9279 0.915897 15.9433 0.910973 15.9536C0.364389 16.3552 0 17.0193 0 17.7657C0 18.9807 0.950367 19.9742 2.11247 19.9742H16.9589C18.121 19.9742 19.0713 18.9807 19.0713 17.7657C19.0713 16.5508 18.121 15.5572 16.9589 15.5572Z' fill='%239E77ED'/%3E%3Cpath d='M2.11247 4.42214C3.2795 4.42214 4.22495 3.43372 4.22495 2.21364C4.22495 0.993565 3.2795 0 2.11247 0C0.945442 0 0 0.988417 0 2.20849C0 3.42857 0.945442 4.41699 2.11247 4.41699V4.42214Z' fill='%239E77ED'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2309_18823'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }

    &.on {
      background: #fff;
      height: 56px;
    }

    &.main {
      &.top {
        background: #fff;

        &.on {
          background: #fff;
          height: 56px;
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .main_header {
    height: 56px;
    background: #fff;
    padding: 8px;
    border-bottom: 1px solid $neutral-200;

    >.hamburger_button {
      display: block;
      width: 40px;
      height: 40px;
      background: url(../../../../assets/images/icon_hamburger.png) no-repeat 50% 50%;
      background-size: contain;
      cursor: pointer;
      display: none;

      &.on {
        background: url(../../../../assets/images/icon_hamburger_on.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    >.main_header_left {

      // logo
      >.logo {
        position: absolute;
        top: calc(50% - 11px);
        left: calc(50% - 34.5px);
        width: 69px;
        height: 22px;
        background: url(../../../../assets/images/studio/studio_logo_mobile.png) no-repeat 0 50%;
        background-size: cover;
        margin-right: 0;
        margin-top: 0;
        overflow: hidden;
      }
    }

    // main header right
    >.main_header_right {
      padding-top: 4px;

      // my menu
      >.avatar {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        // background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='16' fill='%23F2F4F7'/%3E%3Cpath d='M16.0013 14.9999C16.7881 14.9999 17.5571 14.7666 18.2113 14.3295C18.8655 13.8924 19.3753 13.2712 19.6764 12.5443C19.9775 11.8175 20.0562 11.0177 19.9027 10.246C19.7493 9.47439 19.3704 8.7656 18.8141 8.20928C18.2578 7.65297 17.549 7.27411 16.7774 7.12062C16.0057 6.96714 15.2059 7.04591 14.479 7.34699C13.7522 7.64806 13.1309 8.15792 12.6938 8.81208C12.2567 9.46623 12.0234 10.2353 12.0234 11.0221C12.0234 12.0771 12.4425 13.0888 13.1885 13.8348C13.9345 14.5808 14.9463 14.9999 16.0013 14.9999Z' fill='%2398A2B3'/%3E%3Cpath d='M21.9671 24.9557C22.2309 24.9557 22.4838 24.851 22.6703 24.6645C22.8568 24.478 22.9616 24.225 22.9616 23.9613C22.9616 22.115 22.2282 20.3444 20.9227 19.0389C19.6172 17.7334 17.8466 17 16.0003 17C14.1541 17 12.3835 17.7334 11.078 19.0389C9.77248 20.3444 9.03906 22.115 9.03906 23.9613C9.03906 24.225 9.14384 24.478 9.33034 24.6645C9.51683 24.851 9.76978 24.9557 10.0335 24.9557H21.9671Z' fill='%2398A2B3'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;

        >.my_menu_box {
          position: absolute;
          top: calc(100% + 10px);
        }
      }

      // play button
      .normal_button {
        >p {
          display: none;
        }

        >.icon {
          &.trailing {
            background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2309_18823)'%3E%3Cpath d='M16.9589 15.5572H7.26809L18.4607 3.85583C18.4607 3.85583 18.4706 3.84554 18.4755 3.84039C18.899 3.4337 19.1698 2.85712 19.1698 2.21362C19.1698 0.998692 18.2195 0.00512695 17.0574 0.00512695H7.9427C6.78059 0.00512695 5.83023 0.998692 5.83023 2.21362C5.83023 3.42855 6.78059 4.42212 7.9427 4.42212H11.9411L0.93067 15.9279C0.93067 15.9279 0.915897 15.9433 0.910973 15.9536C0.364389 16.3552 0 17.0193 0 17.7657C0 18.9807 0.950367 19.9742 2.11247 19.9742H16.9589C18.121 19.9742 19.0713 18.9807 19.0713 17.7657C19.0713 16.5508 18.121 15.5572 16.9589 15.5572Z' fill='%239E77ED'/%3E%3Cpath d='M2.11247 4.42214C3.2795 4.42214 4.22495 3.43372 4.22495 2.21364C4.22495 0.993565 3.2795 0 2.11247 0C0.945442 0 0 0.988417 0 2.20849C0 3.42857 0.945442 4.41699 2.11247 4.41699V4.42214Z' fill='%239E77ED'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2309_18823'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }

    &.on {
      background: #fff;
      height: 56px;
    }

    &.main {
      &.top {
        background: #fff;

        &.on {
          background: #fff;
          height: 56px;
        }
      }
    }
  }
}