// myContent.scss

@import '../../../assets/css/foundation.scss';

.my-contents {
  &__inner {
    position: relative;
    overflow: inherit;
    .item__box {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      > .title {
        width: 100%;
        @include NanumSquare-label-16B($font-color: $neutral-700, $align: left);
        margin: 28px 0 24px;
      }
      &__inner {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        gap: 60px;
        padding: 0 15px;
       
        > .item {
          flex: 1;
          @include flex(flex, $display-flex-column-no, center, center);
          cursor: pointer;
          > .image {
            width: 100%;
            height: 180px;
            background: $primary-25 url(../../../assets/images/temporary/img1.png) no-repeat 50% 50%;
            background-size: 130px 130px;
            border-radius: 8px;
            margin-bottom: 12px;
          }
          > .category {
            width: 100%;
            @include NanumSquare-label-11($font-color: $neutral-500, $align: left);
            margin-bottom: 2px;
          }
          > .name {
            width: 100%;
            @include NanumSquare-label-14($font-color: $neutral-700, $align: left);
            margin-bottom: 14px;
          }
          > .price_box {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, flex-start);
            > .price {
              @include NanumSquare-label-12UB($font-color: $primary-600);
            }
            &.free {
              > .free {
                @include NanumSquare-label-12UB($font-color: $secondary-400);
                margin-right: 8px;
              }
              > .price {
                text-decoration: line-through;
              }
            }
          }
          &:nth-of-type(1) {
            > .image {
              background: $primary-25 url(../../../assets/images/temporary/img1.png) no-repeat 50% 50%;
              background-size: 130px 130px;
            }
          }
          &:nth-of-type(2) {
            > .image {
              background: $primary-25 url(../../../assets/images/temporary/img2.png) no-repeat 50% 50%;
              background-size: 130px 130px;
            }
          }
          &:nth-of-type(3) {
            > .image {
              background: $primary-25 url(../../../assets/images/temporary/img3.png) no-repeat 50% 50%;
              background-size: 130px 130px;
            }
          }
          &:nth-of-type(4) {
            > .category {
              content: "상의";
            }
            > .image {
              background: $primary-25 url(../../../assets/images/temporary/img4.png) no-repeat 50% 50%;
              background-size: 130px 130px;
            }
          }
        }
      }
      &.하의 {
        > .title {
          margin-top: 56px;
        }
        > .item__box__inner {
          > .item {
            &:nth-of-type(1) {
              > .image {
                background: $primary-25 url(../../../assets/images/temporary/img5.png) no-repeat 50% 50%;
                background-size: 130px 130px;
              }
            }
            &:nth-of-type(2) {
              > .image {
                background: $primary-25 url(../../../assets/images/temporary/img6.png) no-repeat 50% 50%;
                background-size: 130px 130px;
              }
            }
            &:nth-of-type(3) {
              > .image {
                background: $primary-25 url(../../../assets/images/temporary/img7.png) no-repeat 50% 50%;
                background-size: 130px 130px;
              }
            }
            &:nth-of-type(4) {
              > .category {
                content: "하의";
              }
              > .image {
                background: $primary-25 url(../../../assets/images/temporary/img8.png) no-repeat 50% 50%;
                background-size: 130px 130px;
              }
            }
          }
        }
      }
    }
    > .normal_button {
      position: absolute;
      top: 18px;
      right: 0;
      > .icon {
        &.leading {
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20M4 19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H20V2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V19.5Z' stroke='%23344054' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 0% 50%;
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }
}


@media (max-width: 1402px) {
  .my-contents {
    &__inner {
      .item__box {
        > .title {
          width: 100%;
          @include NanumSquare-label-16B($font-color: $neutral-700, $align: left);
          margin: 16px 0 24px;
          
        }
        &__inner {
          > .item {
            &:nth-of-type(4) {
              display: none;
            }
          }
        }
        &.하의 {
          > .title {
            margin-top: 48px;
          }
        }
      }
      > .normal_button {
        top: -2px;
      }
    }
  }
}

@media (max-width: 760px) {
  .my-contents {
    &__inner {
      .item__box {
        width: 100%;
        @include flex(flex, $display-flex-column-no, center, center);
        > .title {
          width: 100%;
          @include NanumSquare-label-16B($font-color: $neutral-700, $align: left);
          margin: 16px 0 24px;
        }
        &__inner {
          gap: 30px;
          > .item {
            &:nth-of-type(3),
            &:nth-of-type(4) {
              display: none;
            }
          }
        }
      }
      > .normal_button {
        padding: 6px 14px !important;
        > .icon.leading {
          margin-right: 0;
        }
        > p {
          display: none;
        }
      }
    }
  }
}