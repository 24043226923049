@import '../../../assets/css/foundation.scss';
.content {
  z-index: 10;
  position: relative;
  // width: calc(100vw - 52px);
  width: 100%;
  // margin-left: 52px;
  height: auto;
  min-height: calc(100vh - 64px);
  
  @include flex(flex, $display-flex-column-no, center, flex-start);
}

@media (max-width: 1402px) {
  .content {
    width: 100vw;
    margin-left: 0;
    min-height: calc(100vh - 56px);
  }
}

@media (max-width: 760px) {
  .content {
    width: 100%;
    margin-left: 0;
    min-height: calc(100vh - 56px);
  }
}